<template>
    <modal name="video-modal"
           transition="nice-modal-fade"
           classes="demo-modal-class"
           :min-width="200"
           :min-height="200"
           :pivot-y="0.5"
           :adaptive="true"
           :scrollable="false"
           :reset="true"
           width="100%"
           height="100%"
           @before-open="beforeOpen"
           @opened="opened"
           @closed="closed"
           @before-close="beforeClose">
        <div class="size-modal-content d-flex justify-content-start" :class="{
            'justify-content-start': !!data,
            'justify-content-center':!data
        }">
            <div id="playerContainer" class="my-auto" ref="playerContainer">
                <video @loadedmetadata="videoLoaded" ref="player" preload="metadata" controls playsinline
                       id="videoplayer" :src="src" :poster="poster"></video>
            </div>
            <b-btn v-if="!!data" @click="$root.$emit('openVideoSearchModal',video)" variant="primary" style="position: fixed; top: 10px; left: 10px;">
                <i class="icon ion-md-search"></i>
            </b-btn>
            <b-btn close @click="$modal.hide('video-modal')" style="position: fixed; top: 10px; right: 10px;">&times;
            </b-btn>
        </div>
        <div v-if="!!data && landscape" style="position: fixed; top: 60px; right: 10px; width:200px; opacity:0.5; font-size:small">
            <b-alert show variant="light">
                <div>球: {{currentMarkerIdx+1}}/{{data.marks.length}}</div>
                <div v-for="(f,i) in filter" :key="'f-'+i">{{f}}</div>
            </b-alert>
            <div style="overflow:auto" :style="{height: height + 'px'}">
                <div class="alert p-1 my-1" v-for="(d, i) in data.marks" :key="'event-'+i" :id="'event-'+i" @click="jumpTo(i)" :class="{
                    'alert-dark': i !== currentMarkerIdx,
                    'alert-primary': i === currentMarkerIdx
                }">
                    <div class="d-flex">
                        <div class="flex-grow-1">
                            {{d.inning}}{{d.top_bottom}}: {{d.pitcher}}<br>
                            {{d.batter}} ({{d.pitches_to_this_batter}})
                        </div>
                        <BattedBallScatter :batted-ball="d.batted_ball" v-if="d.batted_ball"></BattedBallScatter>
                        <OnePointMiniPlot :plot="d.plot" v-if="d.plot"></OnePointMiniPlot>
                    </div>
                </div>
            </div>
        </div>

        <div v-if="!!data && !landscape" style="position: fixed; bottom: 10px; width:100%; opacity:0.5; font-size:small">
            <b-alert show variant="light">
                <div>球: {{currentMarkerIdx+1}}/{{data.marks.length}}</div>
                <div v-for="(f,i) in filter" :key="'f-'+i">{{f}}</div>
            </b-alert>
            <div style="overflow-x: auto; height: 180px">
                <div class="d-flex">
                    <div class="alert p-1 mr-1"
                         v-for="(d, i) in data.marks"
                         :key="'event-'+i"
                         :id="'event-'+i"
                         @click="jumpTo(i)"
                         :class="{
                    'alert-dark': i !== currentMarkerIdx,
                    'alert-primary': i === currentMarkerIdx
                }">
                        <div style="width: 6rem;">
                            <div class="mb-1">
                                {{d.inning}}{{d.top_bottom}}: {{d.pitcher}}<br>
                                {{d.batter}} ({{d.pitches_to_this_batter}})<br>
                            </div>
                            <OnePointMiniPlot :plot="d.plot" v-if="d.plot" class="mr-1"></OnePointMiniPlot>
                            <BattedBallScatter :batted-ball="d.batted_ball" v-if="d.batted_ball"></BattedBallScatter>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </modal>
</template>

<script>
import Hls from 'hls.js/dist/hls.min';
import OnePointMiniPlot from "@/components/OnePointMiniPlot.vue";
import BattedBallScatter from "@/components/BattedBallScatter.vue";

export default {
    name: 'VideoModal',
    components: {BattedBallScatter, OnePointMiniPlot},
    data() {
        const landscape = screen.orientation ? screen.orientation.type.startsWith('landscape') : window.innerWidth > window.innerHeight;
        return {
            hls: null,
            player: null,
            src: null,
            url: null,
            poster: null,
            loaded: false,
            data: null,
            currentMarkerIdx: 0,
            filter: '',
            currentTime: 0,
            height: window.innerHeight - 150,
            video: null,
            landscape
        }
    },
    watch: {
        currentMarkerIdx(v,o) {
            if (v !== o) {
                const element = document.querySelector('#event-'+v)
                if (element) {
                    element.scrollIntoView({ behavior: "smooth", block: "center", inline: "nearest" });
                }
            }
        }
    },
    methods: {
        beforeOpen() {
            document.body.classList.add('body-no-scroll');
        },
        beforeClose() {
            this.player.pause();
            document.body.classList.remove('body-no-scroll');
        },
        opened() {
            // e.ref should not be undefined here
            this.playerInitialize();
        },
        closed() {
            if (this.player) {
                this.player.stop();
                this.player.destroy();
                this.player = null;
            }
            if (this.hls) {
                this.hls.detachMedia()
                this.hls.destroy()
                this.hls = null;
            }
            this.loaded = false;
            this.src = null;
            this.url = null;
        },
        videoLoaded() {
            if (!this.loaded) {
                this.resizePlayer();
                if (this.data && this.data.marks.length) {
                    if (this.player) {
                        this.player.markers = {
                            enabled: true,
                            points: this.data.marks
                        }
                        this.player.currentTime = this.data.marks[this.currentMarkerIdx].time
                    }
                }
            }
        },
        playerInitialize() {
            if (this.url) {
                const hlsfmp4 = this.url.hlsfmp4;
                if (!(/windows|android/i.test(navigator.userAgent)) && this.$refs.player.canPlayType('application/vnd.apple.mpegurl')) {
                    this.src = hlsfmp4;
                    // this.$refs.player.src = hlsfmp4;
                } else if (Hls.isSupported()) {
                    this.hls = new Hls();
                    this.hls.on(Hls.Events.MEDIA_ATTACHED, () => {
                        this.hls.loadSource(hlsfmp4);
                    });
                    this.hls.attachMedia(this.$refs.player);
                } else {
                    this.src = this.url.mp4;
                }
                // eslint-disable-next-line no-undef
                this.player = new Plyr('#videoplayer', {
                    fullscreen: {enabled: !this.data, fallback: true, iosNative: false, container: null},
                    controls: ['play-large', 'rewind', 'play', 'fast-forward', 'progress', 'current-time', 'mute', 'volume', 'settings', 'pip', 'fullscreen'],
                    hideControls: false,
                    keyboard: {focused: true, global: true},
                    i18n: {
                        restart: '最初から再生',
                        rewind: '巻き戻し {seektime}秒',
                        play: '再生',
                        pause: '一時停止',
                        fastForward: '早送り {seektime}秒',
                        seek: 'シーク',
                        seekLabel: '{currentTime} の {duration}',
                        played: '再生した',
                        buffered: 'バッファーされた',
                        currentTime: '現在時刻',
                        duration: '長さ',
                        volume: 'ボリューム',
                        mute: 'ミュート',
                        unmute: '音',
                        enableCaptions: 'キャプションを有効化',
                        disableCaptions: 'キャプションを無効化',
                        download: 'ダウンロード',
                        enterFullscreen: '全画面モード',
                        exitFullscreen: '全画面モードを閉じる',
                        frameTitle: '{title}のプレイヤー',
                        captions: 'キャプション',
                        settings: '設定',
                        menuBack: '戻る',
                        speed: '再生速度',
                        normal: '普通',
                        quality: '画質',
                        loop: 'ループ',
                        start: '開始',
                        end: '終了',
                        all: 'すべて',
                        reset: 'リセット',
                        disabled: '無効化された',
                        enabled: '有効化された',
                        advertisement: '広告',
                        qualityBadge: {
                            2160: '4K',
                            1440: 'HD',
                            1080: 'HD',
                            720: 'HD',
                            576: 'SD',
                            480: 'SD'
                        },
                        markers: {
                            enabled: !!this.data,
                            points: this.data && this.data.marks ? this.data.marks : []
                        }
                    }
                });
                this.player.on('play', () => {
                    /* eslint-disable no-undef */
                    this.$log( 'play', {'event_label': hlsfmp4});
                })
                this.player.on('pause', () => {
                    /* eslint-disable no-undef */
                    this.$log( 'pause', {'event_label': hlsfmp4});
                })
                this.player.on('seeked', () => {
                    /* eslint-disable no-undef */
                    this.$log( 'seeked', {'event_label': hlsfmp4});
                })
                this.player.on('error', () => {
                    /* eslint-disable no-undef */
                    this.$log( 'error', {'event_label': hlsfmp4});
                })
                if (this.data && this.data.marks && this.data.marks.length) {
                    this.player.on('timeupdate', this.timeUpdate)
                }
            }
        },
        jumpTo(i) {
            if (i < this.data.marks.length) {
                if (this.player) {
                    this.player.currentTime = this.data.marks[i].time
                }
                this.currentMarkerIdx = i;
            }
        },
        timeUpdate() {
            if (this.loaded) {
                if (this.player.currentTime < this.data.marks[0].time) {
                    this.currentMarkerIdx=0
                    this.player.currentTime = this.data.marks[this.currentMarkerIdx].time
                    return
                }
                if (this.player.currentTime >= this.data.marks[this.currentMarkerIdx].endtime) {
                    if (this.currentMarkerIdx < this.data.marks.length - 1) {
                        this.currentMarkerIdx++
                        this.player.currentTime = this.data.marks[this.currentMarkerIdx].time
                    } else {
                        this.player.pause()
                    }
                }
            }
        },
        resizePlayer() {
            if (this.$refs.player) {
                let h = this.$refs.player.videoHeight || this.$refs.player.clientHeight
                let w = this.$refs.player.videoWidth || this.$refs.player.clientWidth
                if (h && w) {
                    let nh = window.innerHeight
                    let nw = Math.floor(nh * (w / h))
                    if (nw > window.innerWidth) {
                        nw = window.innerWidth;
                        nh = Math.floor(nw * (h / w))
                    }
                    // let el = document.querySelector('#playercontainer');
                    this.$refs.playerContainer.style.height = 'auto';
                    // this.$refs.playerContainer.style.height = nh + 'px';
                    this.$refs.playerContainer.style.width = nw + 'px';
                    this.loaded = true;
                }
            }
        },
        resizeOnRotate() {
            if (this.loaded) {
                this.resizePlayer();
                this.height = window.innerHeight - 150;
                if (screen.orientation) {
                    this.landscape = screen.orientation.type.startsWith('landscape');
                } else {
                    this.landscape = window.innerWidth > window.innerHeight;
                }
            }
        },
        openVideo(url,video, data, filter) {
            this.video = video;
            this.loaded = false;
            this.src = null;
            this.url = url;
            this.poster = video.poster;
            this.data = data
            this.filter = filter
            this.currentMarkerIdx = 0
            this.$modal.show('video-modal');
        }
    },
    created() {
        this.$root.$on('openVideo', this.openVideo)
        if (screen.orientation) {
            screen.orientation.addEventListener('change', this.resizeOnRotate, false);
        }
        window.addEventListener('resize', this.resizeOnRotate, false);
    },
    destroyed() {
        this.$root.$off('openVideo', this.openVideo)
        if (screen.orientation){
            screen.orientation.removeEventListener('change', this.resizeOnRotate, false);
        }
        window.removeEventListener('resize', this.resizeOnRotate, false);
    }

}
</script>

<style scoped>
.size-modal-content {
    padding: 0;
    height: 100%;
}

.v--modal-overlay {
    z-index: 9999;
    background: rgba(0, 0, 0, 0.8);
}

.demo-modal-class {
    border-radius: 5px;
    background: #F7F7F7;
    box-shadow: 5px 5px 30px 0px rgba(46, 61, 73, 0.8);
}

#playerContainer {
    min-width: 70%;
    max-width: 100%;
    max-height: 100%;
    /*width: 100%;*/
}

::-webkit-scrollbar {
    width: 10px;
}

::-webkit-scrollbar-track {
    background-color: transparent;
}

::-webkit-scrollbar-thumb {
    background-color: #d6dee1;
}

::-webkit-scrollbar-thumb {
    background-color: #d6dee1;
    border-radius: 10px;
}
</style>
