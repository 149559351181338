<template>
    <b-modal
        id="modalLabelVideo"
        title="映像情報をラベリング"
        size="xl"
        ref="videoLabelModal"
        cancel-title="キャンセル"
        ok-title="保存"
        @ok.prevent="save"
        @shown="playerInitialize"
        @hidden="close"
        @cancel.prevent="cancel"
        centered
        hide-header-close
        no-close-on-esc
        no-close-on-backdrop

    >
        <div v-shortkey="['z']"
             @shortkey="shortcut">
            <div class="row">
                <div class="col-8">
                    <div class="size-modal-content d-flex justify-content-center">
                        <div id="playerContainer" class="my-auto" ref="playerContainer">
                            <video
                                style="width: 100%"
                                @loadedmetadata="videoLoaded"
                                ref="player"
                                preload="metadata"
                                playsinline
                                id="videoplayer2"
                                :src="src"
                                :poster="poster"
                                @durationchange="durationChange"
                                @timeupdate="timeUpdate"
                            />
                        </div>
                    </div>
                </div>
                <div class="col-4">
                    <div class="card info-card" style="min-height: calc(100%);">
                        <b-button-group class="no-round">
                            <b-button variant="info" size="sm" @click="fileSelect" title="読み込み"><i
                                class="icon ion-md-document"></i> 読み込み
                                <input ref="file" type="file" id="upload-file" style="width: 0;" class="inputfile"
                                       @click.stop @change="loadData" accept=".bacs-s">
                            </b-button>
                            <b-button variant="danger" @click="removeData" title="削除"><i
                                class="icon ion-md-trash"></i></b-button>
                            <b-button variant="secondary" @click="exportCSV" title="CSV"><i
                                class="icon ion-md-archive"></i></b-button>
                        </b-button-group>
                        <div class="card-body p-1">
                            <b-list-group ref="chapter_list">
                                <b-list-group-item
                                    v-for="(chapter, i) in gamedata"
                                    :key="'chapter-' + i"
                                    @click="chapterClick(chapter, i)"
                                    :class="{ selected: i === currentChapterIdx }"
                                    :id="'chapter-' + i"
                                >
                                    <div class="row mb-1">
                                        <div class="col-4">
                                            <b-button
                                                v-if="chapter.start_time"
                                                @click.stop="deleteChapter(i)"
                                                variant="danger"
                                                size="sm"
                                            ><i
                                                class="icon ion-md-close mr-1"></i>連携を取消
                                            </b-button>
                                        </div>
                                        <div class="col-8">
                                            {{ i+1 }} : {{ getTime(chapter.start_time) }} ~ {{ getTime(chapter.end_time) }}
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col">
                                            <span class="mr-1 mb-1 badge badge-primary">#{{players[chapter.pitcher.id].uniform_no}}&nbsp;{{ players[chapter.pitcher.id].name }}</span>
                                            <span class="mr-1 mb-1 badge badge-dark">#{{players[chapter.catcher.id].uniform_no}}&nbsp;{{ players[chapter.catcher.id].name }}</span>
                                            <span class="mr-1 mb-1 badge badge-warning">#{{players[chapter.batter.id].uniform_no}}&nbsp;{{ players[chapter.batter.id].name }}</span>
                                            <span class="mr-1 mb-1 badge badge-secondary">{{ chapter.batting_order }}</span>
                                            <span class="mr-1 mb-1 badge badge-success">{{ result['result-'+chapter.batted_ball.result].name }}</span>
                                            <span class="mr-1 mb-1 badge badge-info">{{ pitch_type[chapter.pitch_type].name }}</span>
                                            <span class="mr-1 mb-1 badge badge-light" v-if="chapter.batted_ball.feature">{{ features[chapter.batted_ball.feature] }}</span>
                                        </div>
                                    </div>
                                </b-list-group-item>
                            </b-list-group>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </b-modal>
</template>

<script>
import Hls from "hls.js/dist/hls.min";
import "vue-slider-component/theme/default.css";
import jsonpack from "jsonpack";
import saveAs from 'file-saver'

export default {
    name: "VideoLabelModal",
    data() {
        return {
            hls: null,
            player: null,
            src: null,
            url: null,
            poster: null,
            loaded: false,
            currentTime: 0,
            duration: 0,
            currentChapterIdx: 0,
            gamedata: [],
            players: {},
            teams: {},
            result: {},
            plan: {},
            pitch_type: {},
            marks:[],
            changed: false,
            features: {
                '1': "ゴロ",
                '2': "ライナー",
                '3': "フライ"
            },
            video: null,
            loadFromServer: false,
            needToPause: 0,
            timer: 0
        };
    },
    created() {
        this.$root.$on('openVideoLabelModal', this.open);
        window.addEventListener("orientationchange", this.resizeOnRotate, false);
        window.addEventListener("resize", this.resizeOnRotate, false);
    },
    destroyed() {
        this.$root.$off('openVideoLabelModal', this.open);
        window.removeEventListener("orientationchange", this.resizeOnRotate, false);
        window.removeEventListener("resize", this.resizeOnRotate, false);
    },
    methods: {
        removeData() {
            if (!this.gamedata.length) {
                return
            }
            this.$swal({
                title: 'データ削除',
                html: '全てのデータを削除しますか？',
                type: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#d9534f',
                cancelButtonText: 'キャンセル',
                confirmButtonText: '削除'
            }).then((result) => {
                if (result.value) {
                    this.marks = []
                    this.gamedata = []
                    this.players = {}
                    this.teams = {}
                    this.result = {}
                    this.plan = {}
                    this.game = {}
                    this.stadium = {}
                    this.memo = {}
                    this.weather = {}
                    this.pitch_type = {}
                    this.player.markers = {
                        enabled: true,
                        points: this.marks
                    }
                    clearTimeout(this.timer)
                    localStorage.removeItem(this.video.video_id)
                }
            })
        },
        exportCSV() {
            if (this.marks.length) {
                const csv = this.marks.map(x=>{
                    return x.time+','+x.endtime+','+'"'+x.tip+'"'
                }).join("\n")
                const blob = new Blob([csv], {type: "text/csv;charset=utf-8"});
                saveAs(blob, this.teams[this.game.first_team_id].name+'-'+this.teams[this.game.second_team_id].name+".csv");
            }
        },
        shortcut() {
            this.setChapter(this.currentChapterIdx)
            this.currentChapterIdx++
            this.scrollNextChapter(this.currentChapterIdx)
        },
        open(url, video, data) {
            this.loaded = false;
            this.src = null;
            this.url = url;
            this.poster = video.poster;
            this.video = video;
            this.marks = []
            this.gamedata = []
            this.players = {}
            this.teams = {}
            this.result = {}
            this.plan = {}
            this.game = {}
            this.stadium = {}
            this.memo = {}
            this.weather = {}
            this.pitch_type = {}
            this.loadFromServer = false
            if (data) {
                let unpacked = jsonpack.unpack(data)
                if (unpacked && unpacked.gamedata) {
                    this.loadFromServer = true
                    this.gamedata = unpacked.gamedata.map(x=>Object.freeze(x))
                    this.players = Object.freeze(unpacked.players)
                    this.teams = Object.freeze(unpacked.teams)
                    this.result = Object.freeze(unpacked.result)
                    this.plan = Object.freeze(unpacked.plan)
                    this.game = Object.freeze(unpacked.game)
                    this.stadium = Object.freeze(unpacked.stadium)
                    this.memo = Object.freeze(unpacked.memo)
                    this.weather = Object.freeze(unpacked.weather)
                    this.pitch_type = Object.freeze(unpacked.pitch_type)
                    this.marks = this.gamedata.filter(x => !!x.start_time && !!x.end_time).map(x => {
                        return {
                            time: x.start_time,
                            endtime: x.end_time,
                            tip: x.inning+(x.top_bottom?'表':'裏')+'：'+this.players[x.pitcher.id].name.split(/[\s,\u3000]+/)[0]+'-'+this.players[x.batter.id].name.split(/[\s,\u3000]+/)[0]+' ('+x.pitches_to_this_batter+')'
                        }
                    })
                }
            }
            this.$nextTick(() => {
                this.$refs.videoLabelModal.show();
            })
        },
        fileSelect() {
            this.$refs.file.value = '';
            this.$refs.file.click();
        },
        loadData(e) {
            let file = e.target.files[0];
            if (file) {
                this.$getComponent('App').loading(true)
                let reader = new FileReader();
                reader.addEventListener('load', e => {
                    try {
                        let data = jsonpack.unpack(e.target.result);
                        let players = {}
                        for (const key in data.players) {
                            delete data.players[key].career
                            players[key] = data.players[key]
                        }
                        this.gamedata = data.gamedata.map(x=>Object.freeze(x))
                        this.players = Object.freeze(players)
                        this.teams = Object.freeze(data.teams)
                        this.result = Object.freeze(data.result)
                        this.plan = Object.freeze(data.plan)
                        this.game = Object.freeze(data.game)
                        this.stadium = Object.freeze(data.stadium)
                        this.memo = Object.freeze(data.memo)
                        this.weather = Object.freeze(data.weather)
                        this.pitch_type = Object.freeze(data.pitch_type)

                        this.gamedata.forEach(chapter => {
                            if (!players[chapter.pitcher.id]) {
                                console.log('pitcher',chapter.pitcher.id)
                            }
                            if (!players[chapter.catcher.id]) {
                                console.log('catcher',chapter.catcher.id)
                            }
                            if (!players[chapter.batter.id]) {
                                console.log('batter',chapter.batter.id)
                            }
                        })

                        // const blob = new Blob([JSON.stringify(data)], {type: "application/json;charset=utf-8"});
                        // saveAs(blob, this.teams[this.game.first_team_id].name+'-'+this.teams[this.game.second_team_id].name+".json");
                        data = null
                    } catch (ex) {
                        console.log(ex)
                        if (this.$ab) {
                            this.$ab.notify(ex);
                        }
                    }
                    reader = null
                    this.$root.$emit('endSpinner');
                })
                reader.readAsText(file, 'UTF-8');
                this.$nextTick(()=>{
                    this.$refs.file.value = '';
                })
            }
        },
        setChapter(i) {
            if (i < this.gamedata.length) {
                if (this.currentTime > 0 && this.currentTime < this.duration) {
                    clearTimeout(this.timer)
                    let start_time = this.currentTime - 2
                    let end_time = start_time + 7
                    let data = this.$copy(this.gamedata[i])
                    Object.assign(data, {
                        start_time,
                        end_time
                    })
                    this.$set(this.gamedata, i, Object.freeze(data))
                    // this.marks = this.gamedata.filter(x => !!x.start_time).reduce((a, c) => {
                    //     a[c.start_time.toString()] = c.page
                    //     return a;
                    // }, {})
                    this.marks = this.gamedata.filter(x => !!x.start_time).map(x => {
                        return {
                            time: x.start_time,
                            endtime: x.end_time,
                            tip: x.inning+(x.top_bottom?'表':'裏')+'：'+this.players[x.pitcher.id].name.split(/[\s,\u3000]+/)[0]+'-'+this.players[x.batter.id].name.split(/[\s,\u3000]+/)[0]+' ('+x.pitches_to_this_batter+')'
                        }
                    })
                    this.player.markers = {
                        enabled: true,
                        points: this.marks
                    }
                    this.timer = setTimeout(() => {
                        localStorage.setItem(this.video.video_id, jsonpack.pack({
                            game: this.game,
                            gamedata: this.gamedata,
                            players: this.players,
                            teams: this.teams,
                            result: this.result,
                            plan: this.plan,
                            pitch_type: this.pitch_type,
                            memo: this.memo,
                            stadium: this.stadium,
                            weather: this.weather
                        }))
                    }, 60000)
                    this.changed = true
                }
            }
        },
        deleteChapter(idx) {
            let data = this.$copy(this.gamedata[idx])
            delete data.start_time
            delete data.end_time
            this.$set(this.gamedata, idx, Object.freeze(data))
            this.marks = this.gamedata.filter(x => !!x.start_time).map(x => {
                return {
                    time: x.start_time,
                    endtime: x.end_time,
                    tip: x.inning+(x.top_bottom?'表':'裏')+'：'+this.players[x.pitcher.id].name.split(/[\s,\u3000]+/)[0]+'-'+this.players[x.batter.id].name.split(/[\s,\u3000]+/)[0]+' ('+x.pitches_to_this_batter+')'
                }
            })
            this.player.markers = {
                enabled: true,
                points: this.marks
            }
            this.changed = true
        },
        getTime(sec) {
            if (sec) {
                let secNum = parseInt(sec, 10);
                let hours = Math.floor(secNum / 3600);
                let minutes = Math.floor((secNum - hours * 3600) / 60);
                let seconds = secNum - hours * 3600 - minutes * 60;

                if (hours < 10) hours = "0" + hours;
                if (minutes < 10) minutes = "0" + minutes;
                if (seconds < 10) seconds = "0" + seconds;
                return hours + ":" + minutes + ":" + seconds;
            }
            return '-'
        },
        durationChange(e) {
            if (e.target.duration !== Infinity) {
                this.duration = Math.ceil(e.target.duration);
            }
        },
        timeUpdate() {
            if (this.player) {
                this.currentTime = this.player.currentTime
                if (this.needToPause && this.player.currentTime >= this.needToPause && this.player.playing) {
                    this.needToPause = 0
                    this.player.pause()
                }
            }
        },
        setTime(sec) {
            if (typeof sec === "number") {
                this.currentTime = sec;
            }
            this.player.currentTime = this.currentTime;
        },
        setTime2() {
            this.player.currentTime = this.currentTime;
        },
        chapterClick(c, i) {
            console.log(c, i)
            this.currentChapterIdx = i
            this.needToPause = c.end_time
            this.setTime(c.start_time)
        },
        scrollNextChapter(idx) {
            let li = document.querySelector("#chapter-"+idx);
            li.scrollIntoView({
                block: 'center',
                inline: 'nearest',
                behavior: 'smooth'
            })

        },
        // 以下、modalの処理
        beforeOpen() {
            document.body.classList.add("body-no-scroll");
        },
        beforeClose() {
            if (this.player) {
                this.player.pause();
                this.player.destroy();
                this.player = null;
            }
            if (this.hls) {
                this.hls.detachMedia()
                this.hls.destroy()
                this.hls = null;
            }
            document.body.classList.remove("body-no-scroll");
        },
        save() {
            if (this.gamedata.length || this.loadFromServer) {
                this.$getComponent('App').loading(true)
                this.$http.post('video/'+this.video.video_id+'?oper=data', this.gamedata.length ? {
                    data: jsonpack.pack({
                        game: this.game,
                        gamedata: this.gamedata,
                        players: this.players,
                        teams: this.teams,
                        result: this.result,
                        plan: this.plan,
                        pitch_type: this.pitch_type,
                        memo: this.memo,
                        stadium: this.stadium,
                        weather: this.weather
                    })
                } : {
                    data: ''
                }).then((r) => {
                    this.$root.$emit('endSpinner');
                    if (r) {
                        this.loaded = false;
                        this.src = null;
                        this.url = null;
                        this.$refs.videoLabelModal.hide();
                        this.$root.$emit('updateTable');
                    }
                    clearTimeout(this.timer)
                }).catch((error) => {
                    this.$root.$emit('endSpinner');
                    if (error.response) {
                        if (error.response.data && error.response.data.error) {
                            this.$snotify.error(error.response.data.error);
                        } else {
                            this.$snotify.error('サーバーエラー');
                        }
                    } else if (error.request) {
                        console.log(error.request);
                    } else {
                        console.log(error.config);
                    }
                    if (this.$ab) {
                        this.$ab.notify(error);
                    }
                })
            }
        },
        videoLoaded(e) {
            console.log("loadedmetadata");
            if (e.target) {
                if (!this.loaded) {
                    this.resizePlayer();
                }
            }
        },
        playerInitialize() {
            if (this.url) {
                if (Hls.isSupported()) {
                    this.hls = new Hls();
                    this.hls.on(Hls.Events.MEDIA_ATTACHED, () => {
                        this.hls.loadSource(this.url.hlsfmp4);
                    });
                    this.hls.attachMedia(this.$refs.player);
                } else if (
                    this.$refs.player.canPlayType("application/vnd.apple.mpegurl")
                ) {
                    this.src = this.url.hlsfmp4;
                }
                // eslint-disable-next-line no-undef
                this.player = new Plyr("#videoplayer2", {
                    fullscreen: {enabled: false},
                    controls: ['play-large', 'rewind', 'play', 'fast-forward', 'progress', 'current-time', 'settings'],
                    hideControls: false,
                    keyboard: {focused: true, global: true},
                    i18n: {
                        restart: '最初から再生',
                        rewind: '巻き戻し {seektime}秒',
                        play: '再生',
                        pause: '一時停止',
                        fastForward: '早送り {seektime}秒',
                        seek: 'シーク',
                        seekLabel: '{currentTime} の {duration}',
                        played: '再生した',
                        buffered: 'バッファーされた',
                        currentTime: '現在時刻',
                        duration: '長さ',
                        volume: 'ボリューム',
                        mute: 'ミュート',
                        unmute: '音',
                        enableCaptions: 'キャプションを有効化',
                        disableCaptions: 'キャプションを無効化',
                        download: 'ダウンロード',
                        enterFullscreen: '全画面モード',
                        exitFullscreen: '全画面モードを閉じる',
                        frameTitle: '{title}のプレイヤー',
                        captions: 'キャプション',
                        settings: '設定',
                        menuBack: '戻る',
                        speed: '再生速度',
                        normal: '普通',
                        quality: '画質',
                        loop: 'ループ',
                        start: '開始',
                        end: '終了',
                        all: 'すべて',
                        reset: 'リセット',
                        disabled: '無効化された',
                        enabled: '有効化された',
                        advertisement: '広告',
                        qualityBadge: {
                            2160: '4K',
                            1440: 'HD',
                            1080: 'HD',
                            720: 'HD',
                            576: 'SD',
                            480: 'SD'
                        }
                    },
                    markers: {
                        enabled: true,
                        points: this.marks
                    }
                });
                this.changed = false
            }
        },
        resizePlayer() {
            if (this.$refs.player) {
                let h = this.$refs.player.videoHeight || this.$refs.player.clientHeight;
                let w = this.$refs.player.videoWidth || this.$refs.player.clientWidth;
                if (h && w) {
                    let nh = document.documentElement.clientHeight;
                    let nw = Math.floor(nh * (w / h));
                    if (nw > document.documentElement.clientWidth) {
                        nw = document.documentElement.clientWidth;
                        nh = Math.floor(nw * (h / w));
                    }
                    this.$refs.playerContainer.style.height = "auto";
                    this.$refs.playerContainer.style.width = nw + "px";
                    this.loaded = true;
                    this.$refs.chapter_list.style.height = (this.$refs.playerContainer.clientHeight-40)+'px';
                }
            }
        },
        resizeOnRotate() {
            if (this.loaded) {
                this.resizePlayer();
            }
        },
        close() {
            this.loaded = false;
            this.src = null;
            this.url = null;
            this.marks = []
            this.gamedata = []
            this.players = {}
            this.teams = {}
            this.result = {}
            this.plan = {}
            this.game = {}
            this.stadium = {}
            this.memo = {}
            this.weather = {}
            this.pitch_type = {}
            clearTimeout(this.timer)
            localStorage.removeItem(this.video.video_id)
        },
        cancel() {
            if (this.changed) {
                this.$swal({
                    text: '保存されてない変更があります。本当に閉じますか？',
                    type: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#ed9d2b',
                    cancelButtonText: 'キャンセル',
                    confirmButtonText: '閉じます'
                }).then((result) => {
                    if (result.value) {
                        this.$refs.videoLabelModal.hide();
                    }
                })
            } else {
                this.$nextTick(()=>{
                    this.$refs.videoLabelModal.hide();
                })
            }
        }
    }
}
</script>

<style scoped>
/deep/ #modalLabelVideo .modal-dialog {
    width: 100%;
    max-width: 100%;
}

.list-group {
    height: 400px;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
}

.no-round .btn {
    border-radius: 0;
}

.selected {
    background-color: rgba(46, 114, 234, 0.63);
}
</style>
